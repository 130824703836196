export const getStages = () => {
  return [
    {
      category_id: 1,
      category_name: "Preparation",

      stages: [
        {
          frame_stage_id: 1,
          stage_value: 0,
          stage_name: "Strategic Definition",
          color_code: "#f79c33",
        },
        {
          frame_stage_id: 2,
          stage_value: 1,
          stage_name: "Preparation and Brief",
          color_code: "#eda3c6",
        },
      ],
    },

    {
      category_id: 2,
      category_name: "Design",

      stages: [
        {
          frame_stage_id: 3,
          stage_value: 2,
          stage_name: "Concept Design",
          color_code: "#73ccd2",
        },
        {
          frame_stage_id: 4,
          stage_value: 3,
          stage_name: "Spatial Coordination",
          color_code: "#ffd322",
        },
        {
          frame_stage_id: 5,
          stage_value: 4,
          stage_name: "Technical Design",
          color_code: "#83bf9b",
        },
      ],
    },

    {
      category_id: 3,
      category_name: "Construction",

      stages: [
        {
          frame_stage_id: 6,
          stage_value: 5,
          stage_name: "Manufacturing and Construction",
          color_code: "#a4a8d7",
        },
        {
          frame_stage_id: 7,
          stage_value: 6,
          stage_name: "Handover",
          color_code: "#eed39b",
        },
      ],
    },

    {
      category_id: 4,
      category_name: "Operation",

      stages: [
        {
          frame_stage_id: 8,
          stage_value: 7,
          stage_name: "Use",
          color_code: "#5caad8",
        },
      ],
    },
  ];
};
